import React from "react"
import { navigate } from "gatsby"
import manageUserPathHistory from "../../utils/manageUserPathHistory"

import clickTracking from "../../utils/tracking"
import CaretLeftIcon from "../../../content/assets/caret-left-icon.svg"

const PathwayHeader = ({ headerColor, headerType, pathway, step, parentUri, title }) => {
  // takes step array and capitalizes the first letter, returning the rest of the string. 
  let stepSpace = step ? step.replace(/_/g, " ") : "";
  let stepTitle = stepSpace
  .replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())
  .replace("Right Meds Right Time", "Right Meds/Right Time")
  .replace("Safety And Autonomy", "Safety & Autonomy")
  .replace("Quality Of Life", "Quality of Life");

  return (
    <section className={"pathway-page-header"} style={{ background: headerColor}}>
      <button onClick={() => 
        headerType === "summary" ?
          ( clickTracking("Disease Specific Pathway Header Click", { key: stepTitle } ), navigate("/disease-specific" + pathway.uri) )
        : headerType === "foundation" ? 
          ( clickTracking("Foundation Pathway Header Click", { key: stepTitle }), navigate("/"), manageUserPathHistory(false) )
        : headerType === "interstitial" ? 
          ( clickTracking("Interstitial Page Header Click", { key: stepTitle }), navigate("/"), manageUserPathHistory(false) )
        : parentUri ? 
          ( clickTracking("Parent Page Header Click", { key: stepTitle }), navigate(parentUri) )
        : (clickTracking("Page Header Click", { key: stepTitle }), navigate("/"), manageUserPathHistory(false)) }
        className="button button--breadcrumb">
        <CaretLeftIcon />{" "}
        <span className="button__icon-text">{headerType === "summary" ? "Back to Pathway" : headerType === "interstitial" ? "Back to Pathways Home" : "Back to Pathway Summary"}</span>
      </button>
      { headerType === "interstitial" ?
        <h1 className="pathway-page-header__interstitial">{pathway ? pathway.title  + " Pathway" : null}</h1>
      : headerType === "summary" || headerType === "foundation" ?
      <>
        <h4>{pathway ? pathway.title  + " Pathway" : null}</h4>
        <h1>{stepTitle}</h1>
      </>
      :
      <>
        <h4>{pathway ? pathway + " Pathway" : null}</h4>
        <h1>{stepTitle}{stepTitle && title ? ": " : null}{title ? title : null}</h1>
      </>
      }
    </section>
  )
}

export default PathwayHeader