import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"

import Seo from "../components/seo"
import NavBar from "../components/global-components/navbar"

import PathwayHeader from "../components/template-components/pathwayheader"
import ModuleSummary from "../components/template-components/modulesummary"

const SummaryTemplate = ({ data, pageContext }) => {

  let pathwayStep = {};

  const summaryUri = data.pathway.pathwayFields.overview.stepImage ? true : false;

  const headerColors = data.stepColors.acfOptionsCompassusAppSettings.globalOptions.stepColors;

  //takes the step prop from state, and returns the appropriate step object from either Master Pathway selection, or Disease Interstitial step. 
  Object.entries(data.pathway.pathwayFields).map((step) => {
    if (pageContext.step === step[1].fieldGroupName ) {
      pathwayStep["step"] = step[1]
      Object.entries(headerColors).map((color) => {
        if (step[0] === color[0]) {
          pathwayStep["headerColor"] = color[1]
        }
      })
    }
  })

  return (
    <>
      <Layout>
          <Seo title="Compassus Summary Page" />
          <PathwayHeader headerColor={pathwayStep.headerColor} headerType={ summaryUri ? "summary" : "foundation"} step={pathwayStep.step.fieldGroupName} pathway={data.pathway} />
          <ModuleSummary stepName={pageContext.stepName}step={pathwayStep.step.fieldGroupName} pathway={data.pathway} pathwayStep={pathwayStep.step} />
      </Layout>
      <NavBar/>
    </>
  )
}

export default SummaryTemplate

//Query for Pathway Summary Page. this query will need to be refined based on authentication. Gets ID through context on gatsby.node page and returns targeted data including associated Modules.
export const pageQuery = graphql`
  query SummaryById(
    $id: String!
  ) {
    pathway: wpPathway(id: {eq: $id}) {
      id
      databaseId
      uri
      desiredSlug
      title
      pathwayFields {
        overview {
          fieldGroupName
          stepExcerpt
          stepImage {
            altText
            sourceUrl
            publicUrl
            uri
          }
          stepBlock {
            ... on WpPathway_Pathwayfields_Overview_stepBlock {
              header
              excerpt
              image {
                altText
                sourceUrl
                publicUrl
                uri
              }
              modules {
                module {
                  ... on WpModule {
                    id
                    databaseId
                    title
                    uri
                    content
                    moduleFields {
                      mediaType
                      prettyTitle
                      slideRepeater {
                        slide {
                          ... on WpModule_Modulefields_slideRepeater_Slide_Header {
                            header
                          }
                          ... on WpModule_Modulefields_slideRepeater_Slide_TextBlock {
                            textBlock
                          }
                          ... on WpModule_Modulefields_slideRepeater_Slide_Image {
                            image {
                              sourceUrl
                              publicUrl
                              altText
                              link
                            }
                          }
                          ... on WpModule_Modulefields_slideRepeater_Slide_Embed {
                            embed
                          }
                          ... on WpModule_Modulefields_slideRepeater_Slide_Callout {
                            callout
                          }
                          ... on WpModule_Modulefields_slideRepeater_Slide_KeyConceptModal {
                            keyConceptModal {
                              modalHeader
                              modalBody
                            }
                          }
                          ... on WpModule_Modulefields_slideRepeater_Slide_Accordion {
                            accordionSingular {
                              accordionHeader
                              accordionBody
                            }
                          }
                          ... on WpModule_Modulefields_slideRepeater_Slide_NestedAccordion {
                            accordionNested {
                              accordionLevelOneHeader
                              accordionLevelOneBody
                              accordionLevelTwoRepeater {
                                accordionLevelTwoGroup {
                                  accordionLevelTwoHeader
                                  accordionLevelTwoBody
                                  accordionLevelThreeRepeater {
                                    accordionLevelThreeGroup {
                                      accordionLevelThreeHeader
                                      accordionLevelThreeBody
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            readTime
          }
          stepReadTime
        }
        rightMedsRightTime {
          fieldGroupName
          stepExcerpt
          stepImage {
            altText
            sourceUrl
            publicUrl
            uri
          }
          stepBlock {
            ... on WpPathway_Pathwayfields_RightMedsRightTime_stepBlock {
              header
              excerpt
              image {
                altText
                sourceUrl
                publicUrl
                uri
              }
              modules {
                module {
                  ... on WpModule {
                    id
                    databaseId
                    title
                    uri
                    content
                    moduleFields {
                      mediaType
                      prettyTitle
                      slideRepeater {
                        slide {
                          ... on WpModule_Modulefields_slideRepeater_Slide_Header {
                            header
                          }
                          ... on WpModule_Modulefields_slideRepeater_Slide_TextBlock {
                            textBlock
                          }
                          ... on WpModule_Modulefields_slideRepeater_Slide_Image {
                            image {
                              sourceUrl
                              publicUrl
                              altText
                              link
                            }
                          }
                          ... on WpModule_Modulefields_slideRepeater_Slide_Embed {
                            embed
                          }
                          ... on WpModule_Modulefields_slideRepeater_Slide_Callout {
                            callout
                          }
                          ... on WpModule_Modulefields_slideRepeater_Slide_KeyConceptModal {
                            keyConceptModal {
                              modalHeader
                              modalBody
                            }
                          }
                          ... on WpModule_Modulefields_slideRepeater_Slide_Accordion {
                            accordionSingular {
                              accordionHeader
                              accordionBody
                            }
                          }
                          ... on WpModule_Modulefields_slideRepeater_Slide_NestedAccordion {
                            accordionNested {
                              accordionLevelOneHeader
                              accordionLevelOneBody
                              accordionLevelTwoRepeater {
                                accordionLevelTwoGroup {
                                  accordionLevelTwoHeader
                                  accordionLevelTwoBody
                                  accordionLevelThreeRepeater {
                                    accordionLevelThreeGroup {
                                      accordionLevelThreeHeader
                                      accordionLevelThreeBody
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            readTime
          }
          stepReadTime
        }
        totalPainManagement {
          fieldGroupName
          stepExcerpt
          stepImage {
            altText
            sourceUrl
            publicUrl
            uri
          }
          stepBlock {
            ... on WpPathway_Pathwayfields_TotalPainManagement_stepBlock {
              header
              excerpt
              image {
                altText
                sourceUrl
                publicUrl
                uri
              }
              modules {
                module {
                  ... on WpModule {
                    id
                    databaseId
                    title
                    uri
                    content
                    moduleFields {
                      mediaType
                      prettyTitle
                      slideRepeater {
                        slide {
                          ... on WpModule_Modulefields_slideRepeater_Slide_Header {
                            header
                          }
                          ... on WpModule_Modulefields_slideRepeater_Slide_TextBlock {
                            textBlock
                          }
                          ... on WpModule_Modulefields_slideRepeater_Slide_Image {
                            image {
                              sourceUrl
                              publicUrl
                              altText
                              link
                            }
                          }
                          ... on WpModule_Modulefields_slideRepeater_Slide_Embed {
                            embed
                          }
                          ... on WpModule_Modulefields_slideRepeater_Slide_Callout {
                            callout
                          }
                          ... on WpModule_Modulefields_slideRepeater_Slide_KeyConceptModal {
                            keyConceptModal {
                              modalHeader
                              modalBody
                            }
                          }
                          ... on WpModule_Modulefields_slideRepeater_Slide_Accordion {
                            accordionSingular {
                              accordionHeader
                              accordionBody
                            }
                          }
                          ... on WpModule_Modulefields_slideRepeater_Slide_NestedAccordion {
                            accordionNested {
                              accordionLevelOneHeader
                              accordionLevelOneBody
                              accordionLevelTwoRepeater {
                                accordionLevelTwoGroup {
                                  accordionLevelTwoHeader
                                  accordionLevelTwoBody
                                  accordionLevelThreeRepeater {
                                    accordionLevelThreeGroup {
                                      accordionLevelThreeHeader
                                      accordionLevelThreeBody
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            readTime
          }
          stepReadTime
        }
        symptomManagement {
          fieldGroupName
          stepExcerpt
          stepImage {
            altText
            sourceUrl
            publicUrl
            uri
          }
          stepBlock {
            ... on WpPathway_Pathwayfields_SymptomManagement_stepBlock {
              header
              excerpt
              image {
                altText
                sourceUrl
                publicUrl
                uri
              }
              modules {
                module {
                  ... on WpModule {
                    id
                    databaseId
                    title
                    uri
                    content
                    moduleFields {
                      mediaType
                      prettyTitle
                      slideRepeater {
                        slide {
                          ... on WpModule_Modulefields_slideRepeater_Slide_Header {
                            header
                          }
                          ... on WpModule_Modulefields_slideRepeater_Slide_TextBlock {
                            textBlock
                          }
                          ... on WpModule_Modulefields_slideRepeater_Slide_Image {
                            image {
                              sourceUrl
                              publicUrl
                              altText
                              link
                            }
                          }
                          ... on WpModule_Modulefields_slideRepeater_Slide_Embed {
                            embed
                          }
                          ... on WpModule_Modulefields_slideRepeater_Slide_Callout {
                            callout
                          }
                          ... on WpModule_Modulefields_slideRepeater_Slide_KeyConceptModal {
                            keyConceptModal {
                              modalHeader
                              modalBody
                            }
                          }
                          ... on WpModule_Modulefields_slideRepeater_Slide_Accordion {
                            accordionSingular {
                              accordionHeader
                              accordionBody
                            }
                          }
                          ... on WpModule_Modulefields_slideRepeater_Slide_NestedAccordion {
                            accordionNested {
                              accordionLevelOneHeader
                              accordionLevelOneBody
                              accordionLevelTwoRepeater {
                                accordionLevelTwoGroup {
                                  accordionLevelTwoHeader
                                  accordionLevelTwoBody
                                  accordionLevelThreeRepeater {
                                    accordionLevelThreeGroup {
                                      accordionLevelThreeHeader
                                      accordionLevelThreeBody
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            readTime
          }
          stepReadTime
        }
        greatestConcern {
          fieldGroupName
          stepExcerpt
          stepImage {
            altText
            sourceUrl
            publicUrl
            uri
          }
          stepBlock {
            ... on WpPathway_Pathwayfields_GreatestConcern_stepBlock {
              header
              excerpt
              image {
                altText
                sourceUrl
                publicUrl
                uri
              }
              modules {
                module {
                  ... on WpModule {
                    id
                    databaseId
                    title
                    uri
                    content
                    moduleFields {
                      mediaType
                      prettyTitle
                      slideRepeater {
                        slide {
                          ... on WpModule_Modulefields_slideRepeater_Slide_Header {
                            header
                          }
                          ... on WpModule_Modulefields_slideRepeater_Slide_TextBlock {
                            textBlock
                          }
                          ... on WpModule_Modulefields_slideRepeater_Slide_Image {
                            image {
                              sourceUrl
                              publicUrl
                              altText
                              link
                            }
                          }
                          ... on WpModule_Modulefields_slideRepeater_Slide_Embed {
                            embed
                          }
                          ... on WpModule_Modulefields_slideRepeater_Slide_Callout {
                            callout
                          }
                          ... on WpModule_Modulefields_slideRepeater_Slide_KeyConceptModal {
                            keyConceptModal {
                              modalHeader
                              modalBody
                            }
                          }
                          ... on WpModule_Modulefields_slideRepeater_Slide_Accordion {
                            accordionSingular {
                              accordionHeader
                              accordionBody
                            }
                          }
                          ... on WpModule_Modulefields_slideRepeater_Slide_NestedAccordion {
                            accordionNested {
                              accordionLevelOneHeader
                              accordionLevelOneBody
                              accordionLevelTwoRepeater {
                                accordionLevelTwoGroup {
                                  accordionLevelTwoHeader
                                  accordionLevelTwoBody
                                  accordionLevelThreeRepeater {
                                    accordionLevelThreeGroup {
                                      accordionLevelThreeHeader
                                      accordionLevelThreeBody
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            readTime
          }
          stepReadTime
        }
        safetyAndAutonomy {
          fieldGroupName
          stepExcerpt
          stepImage {
            altText
            sourceUrl
            publicUrl
            uri
          }
          stepBlock {
            ... on WpPathway_Pathwayfields_SafetyAndAutonomy_stepBlock {
              header
              excerpt
              image {
                altText
                sourceUrl
                publicUrl
                uri
              }
              modules {
                module {
                  ... on WpModule {
                    id
                    databaseId
                    title
                    uri
                    content
                    moduleFields {
                      mediaType
                      prettyTitle
                      slideRepeater {
                        slide {
                          ... on WpModule_Modulefields_slideRepeater_Slide_Header {
                            header
                          }
                          ... on WpModule_Modulefields_slideRepeater_Slide_TextBlock {
                            textBlock
                          }
                          ... on WpModule_Modulefields_slideRepeater_Slide_Image {
                            image {
                              sourceUrl
                              publicUrl
                              altText
                              link
                            }
                          }
                          ... on WpModule_Modulefields_slideRepeater_Slide_Embed {
                            embed
                          }
                          ... on WpModule_Modulefields_slideRepeater_Slide_Callout {
                            callout
                          }
                          ... on WpModule_Modulefields_slideRepeater_Slide_KeyConceptModal {
                            keyConceptModal {
                              modalHeader
                              modalBody
                            }
                          }
                          ... on WpModule_Modulefields_slideRepeater_Slide_Accordion {
                            accordionSingular {
                              accordionHeader
                              accordionBody
                            }
                          }
                          ... on WpModule_Modulefields_slideRepeater_Slide_NestedAccordion {
                            accordionNested {
                              accordionLevelOneHeader
                              accordionLevelOneBody
                              accordionLevelTwoRepeater {
                                accordionLevelTwoGroup {
                                  accordionLevelTwoHeader
                                  accordionLevelTwoBody
                                  accordionLevelThreeRepeater {
                                    accordionLevelThreeGroup {
                                      accordionLevelThreeHeader
                                      accordionLevelThreeBody
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            readTime
          }
          stepReadTime
        }
        qualityOfLife {
          fieldGroupName
          stepExcerpt
          stepImage {
            altText
            sourceUrl
            publicUrl
            uri
          }
          stepBlock {
            ... on WpPathway_Pathwayfields_QualityOfLife_stepBlock {
              header
              excerpt
              image {
                altText
                sourceUrl
                publicUrl
                uri
              }
              modules {
                module {
                  ... on WpModule {
                    id
                    databaseId
                    title
                    uri
                    content
                    moduleFields {
                      mediaType
                      prettyTitle
                      slideRepeater {
                        slide {
                          ... on WpModule_Modulefields_slideRepeater_Slide_Header {
                            header
                          }
                          ... on WpModule_Modulefields_slideRepeater_Slide_TextBlock {
                            textBlock
                          }
                          ... on WpModule_Modulefields_slideRepeater_Slide_Image {
                            image {
                              sourceUrl
                              publicUrl
                              altText
                              link
                            }
                          }
                          ... on WpModule_Modulefields_slideRepeater_Slide_Embed {
                            embed
                          }
                          ... on WpModule_Modulefields_slideRepeater_Slide_Callout {
                            callout
                          }
                          ... on WpModule_Modulefields_slideRepeater_Slide_KeyConceptModal {
                            keyConceptModal {
                              modalHeader
                              modalBody
                            }
                          }
                          ... on WpModule_Modulefields_slideRepeater_Slide_Accordion {
                            accordionSingular {
                              accordionHeader
                              accordionBody
                            }
                          }
                          ... on WpModule_Modulefields_slideRepeater_Slide_NestedAccordion {
                            accordionNested {
                              accordionLevelOneHeader
                              accordionLevelOneBody
                              accordionLevelTwoRepeater {
                                accordionLevelTwoGroup {
                                  accordionLevelTwoHeader
                                  accordionLevelTwoBody
                                  accordionLevelThreeRepeater {
                                    accordionLevelThreeGroup {
                                      accordionLevelThreeHeader
                                      accordionLevelThreeBody
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            readTime
          }
          stepReadTime
        }
      }
    }
    stepColors: wp {
      acfOptionsCompassusAppSettings {
        globalOptions {
          stepColors {
            totalPainManagement
            symptomManagement
            safetyAndAutonomy
            rightMedsRightTime
            qualityOfLife
            overview
            greatestConcern
          }
        }
      }
    }
  }
`