import React, { useState, useEffect } from "react"
import { Link} from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { TransitionGroup, CSSTransition } from 'react-transition-group';

import clickTracking from "../../utils/tracking"
import CompletionCheckmark from "../global-components/completioncheckmark"

import CaretDownIcon from "../../../content/assets/caret-down-icon.svg"
import CaretUpIcon from "../../../content/assets/caret-up-icon.svg"

import ArrowRightIcon from "../../../content/assets/arrow-right-icon.svg"
import TextIcon from "../../../content/assets/text-icon.svg"
import AudioIcon from "../../../content/assets/audio-icon.svg"
import ImageIcon from "../../../content/assets/image-icon.svg"
import VideoIcon from "../../../content/assets/video-icon.svg"
import ToolIcon from "../../../content/assets/tool-icon.svg"
import { useProgressContext } from "../../progressWrapper";

const MediaIcons = (mediaType) => {

  const mediaNew = Array.from(mediaType.mediaType);

    return (
      mediaNew.map((media, index) => {
        return (
          media === "audio" ? <AudioIcon key={index} />
          : media === "video" ? <VideoIcon key={index} />
          : media === "visual aids" ? <ImageIcon key={index} />
          : media === "text" ? <TextIcon key={index} style={{ opacity: 0.5}} />
          : media === "tools" ? <ToolIcon key={index} style={{ opacity: 0.5}} />
          : ""
        )
      })
    )
}

/**
 * ModuleListItem component is used to create list of modules and display types of content within slides (https://www.figma.com/file/eYDH6nIrMzilYTTtBcxgYW/Compassus-Pathways-3.0?node-id=1440%3A2108).
 * Props: title (string), destinationUrl (string url), complete (boolean)
 */

const ModuleListItem = ({title, destinationUrl, mediaType, percentComplete}) => {

  return (
    <Link className="module_list_item" onClick={() => {clickTracking("Module List Item Click", { key: destinationUrl })}} to={destinationUrl}>
      <h3>
        <CompletionCheckmark 
          percentComplete={percentComplete} 
        />
        {title}
      </h3>
      <div className="module_list_item__details">
        { mediaType ? <MediaIcons mediaType={mediaType} /> : null}
        <ArrowRightIcon />
      </div>
    </Link>
  )
}

const StepBlock = ({stepName, pathwayStep, stepBlock, pathway, step, index, readTime}) => {

  const [isOpen, setIsOpen] = useState(false)
  //checks if sessionStorage exists for StepBlock and sets open state to that. 
  useEffect(() => {
    const data = sessionStorage.getItem('open-' + pathway.id + "-" + step + "-" + index);
    if ( data !== null ) setIsOpen(JSON.parse(data));
  }, [])

  //on state change, set sessionStorage variable to isOpen state. Stores as string. 
  useEffect(() => {
    sessionStorage.setItem('open-' + pathway.id + "-" + step + "-" + index, JSON.stringify(isOpen));
  }, [isOpen]);

  const [progressData, createActivity, updateActivity] = useProgressContext();

  const getModuleProgress = (moduleId) => {
    const pathwayId = pathway.databaseId

    if (progressData) {
      const pathwayProgress = progressData.progress.filter(x => x.pathwayPostId == pathwayId)
      if( pathwayProgress[0]) {
        const fieldProgress = pathwayProgress[0].pathwayFields.filter(x => x.name == stepName);
        const moduleProgress = fieldProgress[0].modules.filter(x => x.moduleId == moduleId)
        if ( moduleProgress[0]) {
          if (moduleProgress[0].completed === true) {
            return 100
          }
        }
      }
    }
    return 0
  }

  return (
    <section>
      <div className="content_card">
        <div className="accordion">
            <div className="accordion__header" onClick={() => (clickTracking("Summary Accordion Click", { key: stepBlock.header }), setIsOpen(!isOpen))}>
              <div className="accordion__header__content">
                  { stepBlock.image ?
                    <img
                      key="accordion_key_4"
                      src={stepBlock.image.publicUrl}
                      alt={stepBlock.image.altText}
                    />
                  :
                    <StaticImage
                      src="../../../content/assets/placeholder.png"
                      alt="Compassus Placeholder Image"
                      layout="fixed"
                      quality="100"
                      style={{
                        marginRight: `2rem`
                      }}
                      height={80}
                      width={80}
                    />
                  }
                <div className="accordion__header__content__text">
                  <h2 key="accordion_key_1">{stepBlock.header}</h2>
                  <h5 key="accordion_key_2">{stepBlock.excerpt}</h5>
                  <h6 key="accordion_key_3">Approx. read time: {readTime ? readTime : 5} Minutes </h6>
                </div>
              </div>
              <div>{isOpen ? <CaretUpIcon /> : <CaretDownIcon />}</div>
            </div>
            <TransitionGroup
              component={null}
            >
            {isOpen && (
              <CSSTransition
                in={isOpen}
                timeout={300}
                classNames="accordion-"
              >
              <div className="accordion__body_content accordion__body_content--stepblock">
                { stepBlock.modules ? stepBlock.modules.map((module, index) => {
                  return (
                    module.module ? 
                      <ModuleListItem
                        key={index}
                        mediaType={module.module.moduleFields.mediaType ? module.module.moduleFields.mediaType : null}
                        title={module.module.moduleFields.prettyTitle ? module.module.moduleFields.prettyTitle : module.module.title}
                        destinationUrl={pathway.uri + step.replace(/_/g, "-") + module.module.uri + "-" + module.module.databaseId}
                        percentComplete={getModuleProgress(module.module.databaseId)}
                      />
                    :
                    null
                  )
                })
                :
                  null
                }
              </div>
              </CSSTransition>
            )}
            </TransitionGroup>
          </div>
      </div>
    </section>
    )
}

const SummaryDropdown = ({stepName, pathwayStep, pathway, step}) => {

  return (
    pathwayStep.stepBlock ?
      pathwayStep.stepBlock.map((stepBlock, index) => {
        return (
          <StepBlock
            readTime={stepBlock.readTime}
            key={index}
            index={index}
            stepBlock={stepBlock}
            pathway={pathway}
            step={step} 
            pathwayStep={pathwayStep}
            stepName={stepName}  
          />
        )
      })
    :
    null
  )
}

const ModuleSummary = ({ stepName, pathwayStep, step, pathway }) => {

  return (
    <SummaryDropdown stepName={stepName} step={step} pathway={pathway} pathwayStep={pathwayStep}/>
  )
}

export default ModuleSummary
