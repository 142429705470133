import React, { useEffect, useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { useMsal } from "@azure/msal-react";

import clickTracking from "../../utils/tracking"
import LockIcon from "../../../content/assets/lock-icon.svg"
import BarsIcon from "../../../content/assets/bars.svg"
import CloseIcon from "../../../content/assets/close-icon.svg"
import HelpIcon from "../../../content/assets/help-icon.svg"

const NavBar = () => {
  const [mobileNavIsOpen, setMobileNavIsOpen] = useState(false)

  const { instance } = useMsal();

  const handleLogout = (logoutType) => {
    if (logoutType === "popup") {
        instance.logoutPopup();
    } else if (logoutType === "redirect") {
        instance.logoutRedirect();
    }
  }

  const { wp } = useStaticQuery(
    graphql`
      query {
        wp {
          acfOptionsCompassusAppSettings {
            globalOptions {
              auxiliaryMenu {
                dashboardItems {
                  menuItem {
                    buttonTitle
                    buttonImage {
                      altText
                      sourceUrl
                      publicUrl
                    }
                    buttonUrl {
                      url
                    }
                  }
                }
                menuItems {
                  menuItem {
                    buttonTitle
                    buttonImage {
                      altText
                      sourceUrl
                      publicUrl
                    }
                    buttonUrl {
                      url
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  )

  const dashboardItems = wp.acfOptionsCompassusAppSettings.globalOptions.auxiliaryMenu.dashboardItems ? Object.values(wp.acfOptionsCompassusAppSettings.globalOptions.auxiliaryMenu.dashboardItems) : null;
  const hamburgerItems = wp.acfOptionsCompassusAppSettings.globalOptions.auxiliaryMenu.menuItems ? Object.values(wp.acfOptionsCompassusAppSettings.globalOptions.auxiliaryMenu.menuItems) : null;
  const browser = typeof window !== "undefined" && window;

  useEffect(() => {
    // prevent user scrolling when nav open
    document.querySelector('body').style.overflow = mobileNavIsOpen ? "hidden" : "auto";
  }, [mobileNavIsOpen]);

  return (
    <>
      <div className="nav-bar">      
        <div className="nav-buttons">
          { dashboardItems ? 
            dashboardItems.map((menuItem, index) => {
              return (
                <a 
                onClick={() => {clickTracking("Bottom Dashboard Menu Click", { key: menuItem.menuItem.buttonTitle })}}
                href={menuItem.menuItem.buttonUrl.url} 
                className="nav-buttons__button"
                key={index}
                >
                  <span className="nav-buttons__icon-wrapper">
                    <img src={menuItem.menuItem.buttonImage.publicUrl}  alt={menuItem.menuItem.buttonImage.altText}/>
                  </span>
                  <span className="nav-buttons__label">{menuItem.menuItem.buttonTitle}</span>
                </a>
              )
            })
          : 
          null
          }
          <div className={"nav-bar__button_group " + (mobileNavIsOpen ? "active" : "")}>
            <div 
              className="nav-buttons__button nav-buttons--mobile-toggle"
              onClick={() => (clickTracking("Hamburger Menu Open Click", { key: 'open sesame' }), setMobileNavIsOpen(!mobileNavIsOpen))}
              >
              <span className="nav-buttons__icon-wrapper">
                <CloseIcon className={mobileNavIsOpen ? "active" : ""} /> 
                <BarsIcon className={mobileNavIsOpen ? "" : "active"} />    
              </span>
              <span className="nav-buttons__label">Menu</span>
            </div>
          </div> 
        </div>
      </div>

      <div className="nav-bar">      
        <div className="nav-buttons">
          <div className={"nav-bar__button_group " + (mobileNavIsOpen ? "active" : "")}>
            <div className="nav-buttons--link-list">
              {hamburgerItems ? 
                hamburgerItems.map((menuItem, index) => {
                  return (
                    <a
                    onClick={() => {
                      menuItem.menuItem.buttonUrl.url === "/" && browser.location.pathname === "/" ? 
                        setMobileNavIsOpen(false) 
                      : clickTracking("Hamburger Dashboard Menu Click", { key: menuItem.menuItem.buttonTitle })
                      }}
                    href={menuItem.menuItem.buttonUrl.url} 
                    className={mobileNavIsOpen ? "menu-buttons" : "menu-buttons hidden"}
                    key={index}
                    >
                      <span className="menu-buttons__icon-wrapper">
                        <img src={menuItem.menuItem.buttonImage.publicUrl}  alt={menuItem.menuItem.buttonImage.altText}/>
                      </span>
                      <span className="menu-buttons__label">{menuItem.menuItem.buttonTitle}</span>
                    </a>
                  )
                })
              :
              null
              }
              <a 
                href="mailto:pathways@compassus.com?subject=Attention: Compassus Help Desk" 
                className={mobileNavIsOpen ? "menu-buttons" : "menu-buttons hidden"}
                onClick={() => (clickTracking("Help Desk Item Click", { key: 'help desk' }), setMobileNavIsOpen(false))}
              >
                <span className="menu-buttons__icon-wrapper">
                  <HelpIcon />
                </span>
                <span className="menu-buttons__label">Help Desk</span>
              </a>
              <a
                href="#"
                className={mobileNavIsOpen ? "menu-buttons" : "menu-buttons hidden"}
                onClick={() => (clickTracking("Logout Out Button Click", { key: 'logout' }), handleLogout("redirect"))} key="logoutRedirect">
                <span className="menu-buttons__icon-wrapper">
                  <LockIcon />
                </span>
                <span className="menu-buttons__label">Logout</span>
              </a>
            </div>
          </div> 
        </div>
      </div>
    </>
  )
}

export default NavBar
